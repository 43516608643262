import classNames from "classnames";
import Link from "next/link";
import { Card } from "../Card";
import { getAspectRatioClass } from "~/utils/getAspectRatioClass";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { ShareBox } from "../ShareBox";
import { FavoriteButton } from "../FavoriteButton";
import { ProductBadges } from "../ProductBadges";
import { StrapiProduct } from "~/shared-types";
import { getRoute } from "~/utils/getRoute";
import Icon from "~/assets/icons/Icon";

type Props = {
  product: StrapiProduct;
};

export const ProductCardImage = ({ product }: Props) => {
  const { images, featuredImageOrientation } = product.attributes;
  if (!images.data?.length) return null;

  const [image] = images.data;

  const aspectRatioClass = getAspectRatioClass(image, featuredImageOrientation);

  const linkToProduct = getRoute("/products/:productId", {
    productId: product.id,
  });
  const soldOut = product.attributes.stockStatus === "notInStock";

  return (
    <div className={classNames("relative w-full mb-3", aspectRatioClass)}>
      <Link href={linkToProduct}>
        <Card.Image
          {...getFormattedImageAttributes(image, "large", {
            altFallback: product.attributes.name,
          })}
          className={classNames(
            "absolute w-full object-cover",
            aspectRatioClass
          )}
        />
      </Link>
      <div className="absolute top-2 right-2 flex items-start">
        <ShareBox className="scale-75 origin-top-right" product={product} />
        <FavoriteButton
          className="scale-75 origin-top-right"
          favorite={{ id: product.id, type: "product" }}
        />
      </div>
      <ProductBadges
        product={product}
        className="absolute bottom-2 right-2 pointer-events-none"
      />
      {soldOut && (
        <Icon
          className={classNames(
            "absolute top-2 left-2 pointer-events-none w-10 h-10 lg:w-14 lg:h-14"
          )}
          name="soldOut"
        />
      )}
    </div>
  );
};
